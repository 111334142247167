import CompanyLogo from "../assets/logo.png"
import OfficeImageTree from "../assets/office-tree.jpeg"
import Picture1 from "../assets/1.png"
import Picture2 from "../assets/2.png"
import Picture3 from "../assets/3.png"
import Picture9 from "../assets/9.png"
import CableLogo from "../assets/cables-logo.png"
import BankLogo from "../assets/banks.png"
import DataNYA from "../assets/pdf/NYA.pdf"
import DataNYY from "../assets/pdf/NYY.pdf"
import DataNYM from "../assets/pdf/NYM.pdf"
import DataNYRGbY from "../assets/pdf/NYRGbY.pdf"
import DataNYFGbY from "../assets/pdf/NYFGbY.pdf"
import DataN2XSYNA2XSY from "../assets/pdf/N2XSY-NA2XSY.pdf"
import DataN2XSRYNA2XSRY from "../assets/pdf/N2XSRY-NA2XSRY.pdf"
import DataN2XSEYNA2XSEY from "../assets/pdf/N2XSEY-NA2XSEY.pdf"
import DataN2XSEFGbYNA2XSEFGbY from "../assets/pdf/N2XSEFGbY-NA2XSEFGbY.pdf"
import { PDFViewers } from "../components"

// const detailProduct = {
//   dataTableNYA: {
//       NYA: [
//           { sqmm: "1.5 re", mm_nominal: "0.7", mm_overall: "3.3", kg: "19", mohm: "50", tipe_a: "15", air_a: "24", kA: "0.17", mm_standard: "100/C" },
//           { sqmm: "1.5 rm", mm_nominal: "0.7", mm_overall: "3.4", kg: "20", mohm: "50", tipe_a: "15", air_a: "24", kA: "0.17", mm_standard: "100/C" },
//           { sqmm: "2.5 re", mm_nominal: "0.8", mm_overall: "3.9", kg: "31", mohm: "50", tipe_a: "19", air_a: "32", kA: "0.29", mm_standard: "100/C" },
//           { sqmm: "2.5 rm", mm_nominal: "0.8", mm_overall: "4.2", kg: "33", mohm: "50", tipe_a: "19", air_a: "32", kA: "0.29", mm_standard: "100/C" },
//           { sqmm: "4 re", mm_nominal: "0.8", mm_overall: "4.4", kg: "45", mohm: "50", tipe_a: "25", air_a: "42", kA: "0.46", mm_standard: "100/C" },
//           { sqmm: "4 rm", mm_nominal: "0.8", mm_overall: "4.7", kg: "48", mohm: "50", tipe_a: "25", air_a: "42", kA: "0.46", mm_standard: "100/C" },
//           { sqmm: "6 re", mm_nominal: "0.8", mm_overall: "4.9", kg: "65", mohm: "50", tipe_a: "33", air_a: "54", kA: "0.69", mm_standard: "100/C" },
//           { sqmm: "6 rm", mm_nominal: "0.8", mm_overall: "5.4", kg: "71", mohm: "50", tipe_a: "33", air_a: "54", kA: "0.69", mm_standard: "100/C" },
//           { sqmm: "10 re", mm_nominal: "1.0", mm_overall: "6.1", kg: "92", mohm: "40", tipe_a: "45", air_a: "73", kA: "1.15", mm_standard: "100/C" },
//           { sqmm: "10 rm", mm_nominal: "1.0", mm_overall: "6.8", kg: "108", mohm: "40", tipe_a: "45", air_a: "73", kA: "1.15", mm_standard: "100/C" },
//           { sqmm: "16 rm", mm_nominal: "1.0", mm_overall: "8.0", kg: "175", mohm: "40", tipe_a: "61", air_a: "98", kA: "1.84", mm_standard: "3000/D" },
//           { sqmm: "25 rm", mm_nominal: "1.2", mm_overall: "9.8", kg: "273", mohm: "40", tipe_a: "83", air_a: "129", kA: "2.88", mm_standard: "3000/D" },
//           { sqmm: "35 rm", mm_nominal: "1.2", mm_overall: "11", kg: "367", mohm: "40", tipe_a: "103", air_a: "158", kA: "4.03", mm_standard: "2000/D" },
//           { sqmm: "50 rm", mm_nominal: "1.4", mm_overall: "13", kg: "523", mohm: "30", tipe_a: "132", air_a: "197", kA: "5.75", mm_standard: "1000/D" },
//           { sqmm: "70 rm", mm_nominal: "1.4", mm_overall: "15", kg: "692", mohm: "30", tipe_a: "165", air_a: "245", kA: "8.05", mm_standard: "1000/D" },
//           { sqmm: "95 rm", mm_nominal: "1.6", mm_overall: "17", kg: "962", mohm: "30", tipe_a: "197", air_a: "290", kA: "10.93", mm_standard: "1000/D" },
//           { sqmm: "120 rm", mm_nominal: "1.6", mm_overall: "19", kg: "1192", mohm: "30", tipe_a: "235", air_a: "345", kA: "13.80", mm_standard: "500/D" },
//           { sqmm: "150 rm", mm_nominal: "1.8", mm_overall: "21", kg: "1511", mohm: "20", tipe_a: "-", air_a: "390", kA: "17.25", mm_standard: "500/D" },
//           { sqmm: "185 rm", mm_nominal: "2.0", mm_overall: "23.5", kg: "1844", mohm: "20", tipe_a: "-", air_a: "445", kA: "21.28", mm_standard: "500/D" },
//           { sqmm: "240 rm", mm_nominal: "2.2", mm_overall: "26.5", kg: "2430", mohm: "20", tipe_a: "-", air_a: "525", kA: "27.60", mm_standard: "500/D" },
//           { sqmm: "300 rm", mm_nominal: "2.4", mm_overall: "29.5", kg: "3015", mohm: "20", tipe_a: "-", air_a: "605", kA: "34.50", mm_standard: "500/D" },
//           { sqmm: "400 rm", mm_nominal: "2.6", mm_overall: "33.5", kg: "3863", mohm: "20", tipe_a: "-", air_a: "725", kA: "46.00", mm_standard: "500/D" },
//       ],
//       NYAF: [
//           { sqmm: "0.5 f", mm_wire: "16/0.2", mm_nominal: "0.6", mm_overall: "2.6", kg: "10", mohmkm: "50", pipe_a: "2.5", air_a: "-", kA: "0.06", mm_standard: "100/C" },
//           { sqmm: "0.75 f", mm_wire: "24/0.2", mm_nominal: "0.6", mm_overall: "2.8", kg: "13", mohmkm: "50", pipe_a: "7", air_a: "-", kA: "0.09", mm_standard: "100/C" },
//           { sqmm: "1.0 f", mm_wire: "32/0.2", mm_nominal: "0.6", mm_overall: "3", kg: "16", mohmkm: "50", pipe_a: "11", air_a: "19", kA: "0.12", mm_standard: "100/C" },
//           { sqmm: "1.5 f", mm_wire: "30/0.25", mm_nominal: "0.7", mm_overall: "3.5", kg: "23", mohmkm: "50", pipe_a: "15", air_a: "24", kA: "0.17", mm_standard: "100/C" },
//           { sqmm: "2.5 f", mm_wire: "50/0.25", mm_nominal: "0.8", mm_overall: "4.2", kg: "36", mohmkm: "50", pipe_a: "20", air_a: "32", kA: "0.29", mm_standard: "100/C" },
//           { sqmm: "4 f", mm_wire: "56/0.3", mm_nominal: "0.8", mm_overall: "5", kg: "51", mohmkm: "50", pipe_a: "25", air_a: "41", kA: "0.46", mm_standard: "100/C" },
//           { sqmm: "6 f", mm_wire: "84/0.3", mm_nominal: "0.8", mm_overall: "6.3", kg: "73", mohmkm: "50", pipe_a: "33", air_a: "53", kA: "0.69", mm_standard: "100/C" },
//           { sqmm: "10 f", mm_wire: "84/0.4", mm_nominal: "1.0", mm_overall: "7.4", kg: "116", mohmkm: "40", pipe_a: "45", air_a: "72", kA: "1.15", mm_standard: "100/C" },
//           { sqmm: "16 f", mm_wire: "126/0.4", mm_nominal: "1.0", mm_overall: "8.6", kg: "180", mohmkm: "40", pipe_a: "61", air_a: "97", kA: "1.84", mm_standard: "100/C" },
//           { sqmm: "25 f", mm_wire: "196/0.4", mm_nominal: "1.2", mm_overall: "10.8", kg: "275", mohmkm: "40", pipe_a: "82", air_a: "128", kA: "2.88", mm_standard: "500/C" },
//           { sqmm: "35 f", mm_wire: "278/0.4", mm_nominal: "1.2", mm_overall: "12", kg: "385", mohmkm: "40", pipe_a: "102", air_a: "156", kA: "4.03", mm_standard: "500/C" },
//           { sqmm: "50 f", mm_wire: "398/0.4", mm_nominal: "1.4", mm_overall: "14.5", kg: "550", mohmkm: "30", pipe_a: "131", air_a: "195", kA: "5.75", mm_standard: "500/C" },
//           { sqmm: "70 f", mm_wire: "360/0.5", mm_nominal: "1.4", mm_overall: "16.5", kg: "750", mohmkm: "30", pipe_a: "164", air_a: "243", kA: "8.05", mm_standard: "500/C" },
//           { sqmm: "95 f", mm_wire: "475/0.5", mm_nominal: "1.6", mm_overall: "19.5", kg: "5000", mohmkm: "30", pipe_a: "205", air_a: "287", kA: "10.93", mm_standard: "500/C" },
//           { sqmm: "120 f", mm_wire: "608/0.5", mm_nominal: "1.6", mm_overall: "21", kg: "1240", mohmkm: "20", pipe_a: "233", air_a: "342", kA: "13.80", mm_standard: "500/C" },
//           { sqmm: "150 f", mm_wire: "760/0.5", mm_nominal: "1.8", mm_overall: "23.5", kg: "1550", mohmkm: "20", pipe_a: "-", air_a: "386", kA: "17.25", mm_standard: "500/C" },
//           { sqmm: "185 f", mm_wire: "925/0.5", mm_nominal: "2", mm_overall: "26", kg: "1900", mohmkm: "20", pipe_a: "-", air_a: "441", kA: "21.28", mm_standard: "500/C" },
//           { sqmm: "240 f", mm_wire: "1221/0.5", mm_nominal: "2.2", mm_overall: "29.5", kg: "2450", mohmkm: "20", pipe_a: "-", air_a: "525", kA: "27.60", mm_standard: "500/C" },
//           { sqmm: "300 f", mm_wire: "1517/0.5", mm_nominal: "2.4", mm_overall: "32.5", kg: "3025", mohmkm: "20", pipe_a: "-", air_a: "605", kA: "34.50", mm_standard: "500/C" },
//       ]
//   },
//   dataTableNYY: {
//       NYY: [
//           { sq_mm: "1 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.4", outer_diameter_mm: "5.8", weight_kg_per_km: "50", resistance_MOhm_km: "50", a1: "21", a2: "27", a3: "27", a4: "27", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "1 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.4", outer_diameter_mm: "6", weight_kg_per_km: "52", resistance_MOhm_km: "50", a1: "21", a2: "24", a3: "27", a4: "27", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "1 x 2.5 re", diameter_mm: "0.8", insulation_mm: "1.4", outer_diameter_mm: "6.2", weight_kg_per_km: "63", resistance_MOhm_km: "50", a1: "28", a2: "27", a3: "35", a4: "35", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//           { sq_mm: "1 x 2.5 rm", diameter_mm: "0.8", insulation_mm: "1.4", outer_diameter_mm: "6.5", weight_kg_per_km: "67", resistance_MOhm_km: "50", a1: "28", a2: "35", a3: "35", a4: "35", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//           { sq_mm: "1 x 4 re", diameter_mm: "1.0", insulation_mm: "1.4", outer_diameter_mm: "7.1", weight_kg_per_km: "87", resistance_MOhm_km: "50", a1: "38", a2: "37", a3: "45", a4: "46", short_circuit_current_kA: "0.46", length_m: "1000/D" },
//           { sq_mm: "1 x 4 rm", diameter_mm: "1.0", insulation_mm: "1.4", outer_diameter_mm: "7.4", weight_kg_per_km: "92", resistance_MOhm_km: "50", a1: "38", a2: "37", a3: "45", a4: "46", short_circuit_current_kA: "0.46", length_m: "1000/D" },
//           { sq_mm: "1 x 6 re", diameter_mm: "1.0", insulation_mm: "1.4", outer_diameter_mm: "8", weight_kg_per_km: "110", resistance_MOhm_km: "50", a1: "48", a2: "46", a3: "57", a4: "57", short_circuit_current_kA: "0.69", length_m: "1000/D" },
//           { sq_mm: "1 x 6 rm", diameter_mm: "1.0", insulation_mm: "1.4", outer_diameter_mm: "8.6", weight_kg_per_km: "117", resistance_MOhm_km: "50", a1: "48", a2: "46", a3: "57", a4: "57", short_circuit_current_kA: "0.69", length_m: "1000/D" },
//           { sq_mm: "1 x 10 re", diameter_mm: "1.0", insulation_mm: "1.4", outer_diameter_mm: "8.5", weight_kg_per_km: "157", resistance_MOhm_km: "50", a1: "65", a2: "64", a3: "76", a4: "76", short_circuit_current_kA: "1.15", length_m: "1000/D" },
//           { sq_mm: "1 x 10 rm", diameter_mm: "1.0", insulation_mm: "1.4", outer_diameter_mm: "8.8", weight_kg_per_km: "163", resistance_MOhm_km: "50", a1: "65", a2: "64", a3: "76", a4: "76", short_circuit_current_kA: "1.15", length_m: "1000/D" },
//           { sq_mm: "1 x 16 rm", diameter_mm: "1.0", insulation_mm: "1.4", outer_diameter_mm: "10.4", weight_kg_per_km: "227", resistance_MOhm_km: "40", a1: "84", a2: "84", a3: "97", a4: "98", short_circuit_current_kA: "1.84", length_m: "1000/D" },
//           { sq_mm: "1 x 25 rm", diameter_mm: "1.0", insulation_mm: "1.4", outer_diameter_mm: "11.6", weight_kg_per_km: "336", resistance_MOhm_km: "40", a1: "117", a2: "114", a3: "117", a4: "114", short_circuit_current_kA: "2.88", length_m: "1000/D" },
//           { sq_mm: "1 x 35 rm", diameter_mm: "1.2", insulation_mm: "1.4", outer_diameter_mm: "12.7", weight_kg_per_km: "437", resistance_MOhm_km: "40", a1: "144", a2: "140", a3: "150", a4: "140", short_circuit_current_kA: "4.03", length_m: "1000/D" },
//           { sq_mm: "1 x 50 rm", diameter_mm: "1.4", insulation_mm: "1.4", outer_diameter_mm: "14.3", weight_kg_per_km: "562", resistance_MOhm_km: "30", a1: "177", a2: "172", a3: "180", a4: "172", short_circuit_current_kA: "5.75", length_m: "1000/D" },
//           { sq_mm: "1 x 70 rm", diameter_mm: "1.4", insulation_mm: "1.4", outer_diameter_mm: "16", weight_kg_per_km: "768", resistance_MOhm_km: "30", a1: "225", a2: "218", a3: "218", a4: "218", short_circuit_current_kA: "8.05", length_m: "1000/D" },
//           { sq_mm: "1 x 95 rm", diameter_mm: "1.6", insulation_mm: "1.5", outer_diameter_mm: "18.5", weight_kg_per_km: "1049", resistance_MOhm_km: "30", a1: "278", a2: "270", a3: "260", a4: "270", short_circuit_current_kA: "10.93", length_m: "1000/D" },
//           { sq_mm: "1 x 120 rm", diameter_mm: "1.6", insulation_mm: "1.5", outer_diameter_mm: "20", weight_kg_per_km: "1280", resistance_MOhm_km: "20", a1: "325", a2: "315", a3: "296", a4: "300", short_circuit_current_kA: "13.8", length_m: "1000/D" },
//           { sq_mm: "1 x 150 rm", diameter_mm: "1.8", insulation_mm: "1.6", outer_diameter_mm: "22.5", weight_kg_per_km: "1597", resistance_MOhm_km: "20", a1: "373", a2: "362", a3: "331", a4: "336", short_circuit_current_kA: "17.25", length_m: "1000/D" },
//           { sq_mm: "1 x 185 rm", diameter_mm: "2.0", insulation_mm: "1.7", outer_diameter_mm: "24.5", weight_kg_per_km: "1959", resistance_MOhm_km: "20", a1: "433", a2: "420", a3: "374", a4: "379", short_circuit_current_kA: "21.28", length_m: "1000/D" },
//           { sq_mm: "1 x 240 rm", diameter_mm: "2.2", insulation_mm: "1.8", outer_diameter_mm: "28", weight_kg_per_km: "2538", resistance_MOhm_km: "20", a1: "518", a2: "503", a3: "432", a4: "439", short_circuit_current_kA: "27.6", length_m: "1000/D" },
//           { sq_mm: "1 x 300 rm", diameter_mm: "2.4", insulation_mm: "1.9", outer_diameter_mm: "31", weight_kg_per_km: "3157", resistance_MOhm_km: "20", a1: "598", a2: "580", a3: "486", a4: "494", short_circuit_current_kA: "34.5", length_m: "1000/D" },
//           { sq_mm: "1 x 400 rm", diameter_mm: "2.6", insulation_mm: "2.0", outer_diameter_mm: "34", weight_kg_per_km: "3995", resistance_MOhm_km: "20", a1: "695", a2: "674", a3: "549", a4: "558", short_circuit_current_kA: "46", length_m: "1000/D" },
//           { sq_mm: "1 x 500 rm", diameter_mm: "2.8", insulation_mm: "2.1", outer_diameter_mm: "38", weight_kg_per_km: "5075", resistance_MOhm_km: "20", a1: "806", a2: "781", a3: "618", a4: "629", short_circuit_current_kA: "57.5", length_m: "1000/D" },
//           { sq_mm: "1 x 630 rm", diameter_mm: "2.8", insulation_mm: "2.3", outer_diameter_mm: "43", weight_kg_per_km: "6449", resistance_MOhm_km: "20", a1: "930", a2: "901", a3: "692", a4: "704", short_circuit_current_kA: "72.45", length_m: "500/D" }
//       ],
//       NYY2: [
//           { sq_mm: "7 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "14.5", weight_kg_per_km: "324", resistance_MOhm_km: "50", a1: "21", a2: "27", a3: "17", a4: "13", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "7 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "15", weight_kg_per_km: "341", resistance_MOhm_km: "50", a1: "21", a2: "24", a3: "17", a4: "13", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "8 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "15.5", weight_kg_per_km: "362", resistance_MOhm_km: "50", a1: "28", a2: "27", a3: "16", a4: "12", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "8 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "16", weight_kg_per_km: "381", resistance_MOhm_km: "50", a1: "28", a2: "35", a3: "16", a4: "12", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "10 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "17.4", weight_kg_per_km: "434", resistance_MOhm_km: "50", a1: "38", a2: "37", a3: "15", a4: "12", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "10 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "18.1", weight_kg_per_km: "458", resistance_MOhm_km: "50", a1: "38", a2: "37", a3: "15", a4: "12", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "12 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "17.9", weight_kg_per_km: "480", resistance_MOhm_km: "50", a1: "48", a2: "46", a3: "14", a4: "11", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "12 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "18.6", weight_kg_per_km: "506", resistance_MOhm_km: "50", a1: "48", a2: "46", a3: "14", a4: "11", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "14 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "18.7", weight_kg_per_km: "542", resistance_MOhm_km: "50", a1: "65", a2: "64", a3: "13", a4: "11", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "14 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "19.5", weight_kg_per_km: "572", resistance_MOhm_km: "50", a1: "65", a2: "64", a3: "13", a4: "11", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "16 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "19.5", weight_kg_per_km: "599", resistance_MOhm_km: "50", a1: "84", a2: "84", a3: "12", a4: "10", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "16 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "20.3", weight_kg_per_km: "632", resistance_MOhm_km: "50", a1: "117", a2: "114", a3: "12", a4: "10", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "19 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "20.4", weight_kg_per_km: "670", resistance_MOhm_km: "50", a1: "144", a2: "140", a3: "12", a4: "9", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "19 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "21.3", weight_kg_per_km: "707", resistance_MOhm_km: "50", a1: "177", a2: "172", a3: "12", a4: "9", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "21 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "21.4", weight_kg_per_km: "728", resistance_MOhm_km: "50", a1: "225", a2: "218", a3: "11", a4: "9", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "21 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "22.3", weight_kg_per_km: "768", resistance_MOhm_km: "50", a1: "278", a2: "270", a3: "11", a4: "9", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "24 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "22.4", weight_kg_per_km: "821", resistance_MOhm_km: "50", a1: "325", a2: "315", a3: "11", a4: "8", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "24 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "24.4", weight_kg_per_km: "866", resistance_MOhm_km: "50", a1: "373", a2: "362", a3: "11", a4: "8", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "30 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "24.6", weight_kg_per_km: "970", resistance_MOhm_km: "50", a1: "433", a2: "420", a3: "11", a4: "8", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "30 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "25.7", weight_kg_per_km: "1024", resistance_MOhm_km: "50", a1: "518", a2: "503", a3: "11", a4: "8", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "40 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "27.3", weight_kg_per_km: "1226", resistance_MOhm_km: "50", a1: "598", a2: "580", a3: "11", a4: "8", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "40 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "28.5", weight_kg_per_km: "1294", resistance_MOhm_km: "50", a1: "695", a2: "674", a3: "11", a4: "8", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "52 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.9", outer_diameter_mm: "32", weight_kg_per_km: "1585", resistance_MOhm_km: "50", a1: "806", a2: "781", a3: "11", a4: "8", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "52 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.9", outer_diameter_mm: "33.4", weight_kg_per_km: "1673", resistance_MOhm_km: "50", a1: "930", a2: "901", a3: "11", a4: "8", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "61 x 1.5 re", diameter_mm: "0.8", insulation_mm: "2.0", outer_diameter_mm: "33", weight_kg_per_km: "1815", resistance_MOhm_km: "50", a1: "930", a2: "901", a3: "11", a4: "8", short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "61 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "2.0", outer_diameter_mm: "34.6", weight_kg_per_km: "1916", resistance_MOhm_km: "50", a1: "930", a2: "901", a3: "11", a4: "8", short_circuit_current_kA: "0.17", length_m: "1000/D" }
//       ],
//       NYY3: [
//           { sq_mm: "2 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "10.8", weight_kg_per_km: "159", resistance_MOhm_km: "50", a1: "27", a2: "21",  short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "2 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "11.1", weight_kg_per_km: "168", resistance_MOhm_km: "50", a1: "27", a2: "21",  short_circuit_current_kA: "0.17", length_m: "1000/D" },
//           { sq_mm: "2 x 2.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "11.6", weight_kg_per_km: "196", resistance_MOhm_km: "50", a1: "36", a2: "29",  short_circuit_current_kA: "0.29", length_m: "1000/D" },
//           { sq_mm: "2 x 2.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "12.1", weight_kg_per_km: "209", resistance_MOhm_km: "50", a1: "36", a2: "29",  short_circuit_current_kA: "0.29", length_m: "1000/D" },
//           { sq_mm: "2 x 4 re", diameter_mm: "1.0", insulation_mm: "1.8", outer_diameter_mm: "13.3", weight_kg_per_km: "269", resistance_MOhm_km: "50", a1: "47", a2: "38",  short_circuit_current_kA: "0.46", length_m: "1000/D" },
//           { sq_mm: "2 x 4 rm", diameter_mm: "1.0", insulation_mm: "1.8", outer_diameter_mm: "13.9", weight_kg_per_km: "289", resistance_MOhm_km: "50", a1: "47", a2: "38",  short_circuit_current_kA: "0.46", length_m: "1000/D" },
//           { sq_mm: "2 x 6 re", diameter_mm: "1.0", insulation_mm: "1.8", outer_diameter_mm: "14.3", weight_kg_per_km: "333", resistance_MOhm_km: "50", a1: "59", a2: "48",  short_circuit_current_kA: "0.69", length_m: "1000/D" },
//           { sq_mm: "2 x 6 rm", diameter_mm: "1.0", insulation_mm: "1.8", outer_diameter_mm: "15.1", weight_kg_per_km: "359", resistance_MOhm_km: "50", a1: "59", a2: "48",  short_circuit_current_kA: "0.69", length_m: "1000/D" },
//           { sq_mm: "2 x 10 re", diameter_mm: "1.0", insulation_mm: "1.8", outer_diameter_mm: "16.1", weight_kg_per_km: "452", resistance_MOhm_km: "50", a1: "78", a2: "66",  short_circuit_current_kA: "1.15", length_m: "1000/D" },
//           { sq_mm: "2 x 10 rm", diameter_mm: "1.0", insulation_mm: "1.8", outer_diameter_mm: "16.8", weight_kg_per_km: "485", resistance_MOhm_km: "50", a1: "78", a2: "66",  short_circuit_current_kA: "1.15", length_m: "1000/D" },
//           { sq_mm: "2 x 16 rm", diameter_mm: "1.0", insulation_mm: "1.8", outer_diameter_mm: "18.9", weight_kg_per_km: "657", resistance_MOhm_km: "40", a1: "102", a2: "90",  short_circuit_current_kA: "1.84", length_m: "1000/D" },
//           { sq_mm: "2 x 25 rm", diameter_mm: "1.2", insulation_mm: "1.8", outer_diameter_mm: "22.3", weight_kg_per_km: "959", resistance_MOhm_km: "40", a1: "134", a2: "120",  short_circuit_current_kA: "2.88", length_m: "1000/D" },
//           { sq_mm: "2 x 35 rm", diameter_mm: "1.2", insulation_mm: "1.8", outer_diameter_mm: "24.5", weight_kg_per_km: "1224", resistance_MOhm_km: "40", a1: "160", a2: "150", short_circuit_current_kA: "4.03", length_m: "1000/D" },
//           { sq_mm: "2 x 50 rm", diameter_mm: "1.4", insulation_mm: "1.8", outer_diameter_mm: "27.7", weight_kg_per_km: "4578", resistance_MOhm_km: "30", a1: "187", a2: "180", short_circuit_current_kA: "5.75", length_m: "1000/D" },
//           { sq_mm: "2 x 70 rm", diameter_mm: "1.4", insulation_mm: "1.9", outer_diameter_mm: "31.4", weight_kg_per_km: "2131", resistance_MOhm_km: "30", a1: "230", a2: "230", short_circuit_current_kA: "8.05", length_m: "1000/D" },
//           { sq_mm: "2 x 95 rm", diameter_mm: "1.6", insulation_mm: "2", outer_diameter_mm: "36.4", weight_kg_per_km: "2905", resistance_MOhm_km: "30", a1: "280", a2: "275", short_circuit_current_kA: "10.93", length_m: "1000/D" },
//           { sq_mm: "2 x 120 rm", diameter_mm: "1.6", insulation_mm: "2.1", outer_diameter_mm: "39.7", weight_kg_per_km: "3531", resistance_MOhm_km: "30", a1: "320", a2: "320", short_circuit_current_kA: "13.80", length_m: "1000/D" },
//           { sq_mm: "2 x 150 rm", diameter_mm: "8.8", insulation_mm: "2.2", outer_diameter_mm: "43.7", weight_kg_per_km: "4321", resistance_MOhm_km: "20", a1: "355", a2: "379", short_circuit_current_kA: "17.25", length_m: "500/D" },
//           { sq_mm: "2 x 185 rm", diameter_mm: "2.0", insulation_mm: "2.4", outer_diameter_mm: "48.6", weight_kg_per_km: "5368", resistance_MOhm_km: "20", a1: "409", a2: "430", short_circuit_current_kA: "21.28", length_m: "500/D" },
//           { sq_mm: "2 x 140 rm", diameter_mm: "2.2", insulation_mm: "2.6", outer_diameter_mm: "55.1", weight_kg_per_km: "6964", resistance_MOhm_km: "20", a1: "472", a2: "510", short_circuit_current_kA: "27.60", length_m: "500/D" },
//           { sq_mm: "2 x 300 rm", diameter_mm: "2.4", insulation_mm: "2.7", outer_diameter_mm: "60.8", weight_kg_per_km: "8605", resistance_MOhm_km: "20", a1: "525", a2: "590", short_circuit_current_kA: "34.50", length_m: "500/D" }
//       ],
//       NYY4: [
//         { sq_mm: "7 x 2.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "15.8", weight_kg_per_km: "421", resistance_MOhm_km: "50", a1: "26", a2: "21", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "7 x 2.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "16.5", weight_kg_per_km: "450", resistance_MOhm_km: "50", a1: "26", a2: "21", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "8 x 2.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "16.9", weight_kg_per_km: "471", resistance_MOhm_km: "50", a1: "23", a2: "18", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "8 x 2.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "17.7", weight_kg_per_km: "505", resistance_MOhm_km: "50", a1: "23", a2: "18", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "10 x 2.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "19.1", weight_kg_per_km: "570", resistance_MOhm_km: "50", a1: "21", a2: "17", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "10 x 2.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "20.1", weight_kg_per_km: "611", resistance_MOhm_km: "50", a1: "21", a2: "17", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "12 x 2.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "19.7", weight_kg_per_km: "637", resistance_MOhm_km: "50", a1: "20", a2: "16", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "12 x 2.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "20.7", weight_kg_per_km: "682", resistance_MOhm_km: "50", a1: "20", a2: "16", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "14 x 2.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "20.5", weight_kg_per_km: "724", resistance_MOhm_km: "50", a1: "18", a2: "15", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "14 x 2.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "21.6", weight_kg_per_km: "777", resistance_MOhm_km: "50", a1: "18", a2: "15", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "16 x 2.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "21.5", weight_kg_per_km: "804", resistance_MOhm_km: "50", a1: "17", a2: "14", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "16 x 2.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "22.6", weight_kg_per_km: "804", resistance_MOhm_km: "50", a1: "17", a2: "14", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "19 x 2.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "22.5", weight_kg_per_km: "907", resistance_MOhm_km: "50", a1: "17", a2: "13", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "19 x 2.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "23.6", weight_kg_per_km: "974", resistance_MOhm_km: "50", a1: "17", a2: "13", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "21 x 2.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "23.6", weight_kg_per_km: "989", resistance_MOhm_km: "50", a1: "16", a2: "12", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "21 x 2.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "24.9", weight_kg_per_km: "1062", resistance_MOhm_km: "50", a1: "16", a2: "12", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "24 x 2.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "25.9", weight_kg_per_km: "1118", resistance_MOhm_km: "50", a1: "15", a2: "11", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "24 x 2.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "27.3", weight_kg_per_km: "1200", resistance_MOhm_km: "50", a1: "15", a2: "11", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "30 x 2.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "27.3", weight_kg_per_km: "1335", resistance_MOhm_km: "50", a1: "14", a2: "11", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "30 x 2.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "28.8", weight_kg_per_km: "1433", resistance_MOhm_km: "50", a1: "14", a2: "11", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "40 x 2.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "30.5", weight_kg_per_km: "1718", resistance_MOhm_km: "50", a1: "14", a2: "11", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "40 x 2.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "32.3", weight_kg_per_km: "1846", resistance_MOhm_km: "50", a1: "14", a2: "11", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "52 x 2.5 re", diameter_mm: "0.8", insulation_mm: "1.9", outer_diameter_mm: "35.8", weight_kg_per_km: "2220", resistance_MOhm_km: "50", a1: "14", a2: "11", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "52 x 2.5 rm", diameter_mm: "0.8", insulation_mm: "1.9", outer_diameter_mm: "37.9", weight_kg_per_km: "2384", resistance_MOhm_km: "50", a1: "14", a2: "11", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "61 x 2.5 re", diameter_mm: "0.8", insulation_mm: "2.0", outer_diameter_mm: "37", weight_kg_per_km: "2552", resistance_MOhm_km: "50", a1: "14", a2: "11", short_circuit_current_kA: "0.29", length_m: "1000/D" },
//         { sq_mm: "61 x 2.5 rm", diameter_mm: "0.8", insulation_mm: "2.0", outer_diameter_mm: "39.1", weight_kg_per_km: "2741", resistance_MOhm_km: "50", a1: "14", a2: "11", short_circuit_current_kA: "0.29", length_m: "1000/D" }
//     ],
//   }
// }

export const data = {
  personal: { 
    logo: CompanyLogo,
    name: "PT.ADITYA BADRATAMA INTERNUSA",
    description: "PT. ADITYA BADRATAMA INTERNUSA was established on October 8, 2003, engaged in the field of Distributor and supplier of electrical cables of the brands KABELINDO, SUPREME, KABELMETAL AND JEMBO, located at JL. RAYA JATIMAKMUR KEMANG NO. 50 Pondok Gede, Bekasi, 17413",
    open: "Senin-Jumat : 8:00 AM To 5:00 PM",
    detail_phone: [
      { phone: "(021) 84977752" },
      { phone: "(021) 84994780" },
    ],
    fax: "(021) 84977634",
    hp: "0811996233",
    email: "aditya_bi@gmail.com",
    address: "RUKO GREEN VILLA BLOK 1A",
    street: "Jl. Raya Jatimakmur Kemang No. 50 Pondok Gede - Bekasi 17413",
    full_address: "RUKO GREEN VILLA BLOK 1A Jl. Raya Jatimakmur Kemang No. 50 Pondok Gede - Bekasi 17413",
    footer: "Copyright © 2024 All Rights Reserved - adityabadratamainternusa.com",
    product: [
        { label: "Cu-PVC (NYA)", detail: () => <PDFViewers data={DataNYA} /> },
        { label: "Cu-PVC-PVC (NYY)", detail: () => <PDFViewers data={DataNYY} /> },
        { label: "Cu-PVC-PVC (NYM)", detail: () => <PDFViewers data={DataNYM} /> },
        { label: "Cu-PVC-SWA-PVC (NYRGbY)", detail: () => <PDFViewers data={DataNYRGbY} /> },
        { label: "Cu-PVC-SFA-PVC (NYFGbY)", detail: () => <PDFViewers data={DataNYFGbY} /> },
        { label: "Cu-XLPE-CTS-PVC-Al-XLPE-CTS-PVC(N2XSY - NA2XSY)", detail: () => <PDFViewers data={DataN2XSYNA2XSY} /> },
        { label: "Cu-XLPE-CTS-AWA-PVC - Al-XLPE-CTS-AWA-PVC (N2XSRY - NA2XSRY)", detail: () => <PDFViewers data={DataN2XSRYNA2XSRY} /> },
        { label: "Cu-XLPE-CTS-PVC - Al-XLPE-CTS-PVC (N2XSEY - NA2XSEY)", detail: () => <PDFViewers data={DataN2XSEYNA2XSEY} /> },
        { label: "Cu-XLPE-CTS-PVC-SFA-PVC - Al-XLPE-CTS-PVC-SFA-PVC (N2XSEFGbY- NA2XSEFGbY)", detail: () => <PDFViewers data={DataN2XSEFGbYNA2XSEFGbY} /> },
    ]
  },
  assets: {
    office: [
      { images: OfficeImageTree },
      { images: Picture1 },
      { images: Picture2 },
      { images: Picture3 },
      { images: Picture9 }
    ],
    cabel_merk: CableLogo,
    bank: BankLogo
  }
}